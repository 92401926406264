.single-background-img {
  background-image: url("../images/bg/ser-2-bg.svg") !important;
}
.about2-shape {
  background-image: url("../images/bg/about-2-shape.jpg") !important;
}
.about2-Bg {
  background-image: url("../images/bg/about-2-bg.jpg");
}
.counter-bg-image {
  background-image: url("../images/bg/counter-bg.jpg");
}
.features-bg-image {
  background-image: url("../images/bg/features-bg.webp");
}
.testimonial-section-6 .testimonial-wrap {
  margin-bottom: 32px !important;
}

.media-center-tab {
  width: 20vw;
  font-size: 21px;
}

.mediaCenterTabs {
  padding-top: 20px;
  text-align: center;
}

.media-font {
  font-weight: 400;
}

.event-backgound-image {
  background-image: url("../images/bg/event-bg.svg");
}
.common_cursor {
  cursor: pointer;
}
.home-brand-section {
  background-color: white;
  height: 55vh;
}
.home-brand-active {
  padding-bottom: 98px;
}
.brand-justify-image {
  justify-content: center;
}
.swiper-pagination-bullet {
  height: 20px !important;
  width: 20px !important;
  margin-top: 10px !important;
}
.retail-background {
  background: linear-gradient(
    90deg,
    rgba(238, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(238, 233, 233, 1) 100%
  );
}
.retail-head-setion {
  background-color: #00aeef;
  text-align: left;
  color: white;
  margin-top: 65px;
  width: 42%;
  padding: 1px 11px;
  font-size: 23px;
  margin-left: 97px;
  margin-bottom: -12px;
  font-weight: 600;
}
.retail-title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  margin-left: 48px;
  text-align: left;
  color: grey !important;
}
.retail-section2-background {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.retail-over-view {
  padding-top: 15px !important;
}
.retail-faq-section {
  padding-top: 62px !important;
}
.reatil-faq-heading-section {
  color: #4c4d56;
  font-size: 25px;
}
.retail-games-section-background {
  background-image: url("../images/bg/testi-bg.jpg");
}
.reatil-video-section-div {
  padding-top: 35px !important;
  padding-bottom: 25px !important;
}

.disk {
  list-style: disc;
  padding: 4px 28px;
}

.color_blue{
  color: #00aeef;
}
.home-banner-img {
  /* padding-top: 10%; */
  /* width: 500px;
  height: 400px; */
}
.image-banner-mobile{
  margin: 0px !important;
  padding-top: 60px !important;
  
}
.home_banner_div{
  height:100%;
  padding:0 12%
}
.home_banner_div_img{
  width:86%;
}
.image-banner-mobile span{
  color: white;
  font-weight: 100;
  
}
@media only screen and (min-width: 1200px) and (max-width: 1480px) {
  .top-left {
    margin-top: -34px;
  }

  h1.retail-heading {
    width: 53% !important;
  }

  p.m-retail-title {
    font-size: 24px !important;
  }
}
@media only screen and (max-width: 767px) {
  p.retail-heading {
    width: 55% !important;
    padding: 1px 11px !important;
    font-size: 26px !important;
    margin-left: 60px !important;
  }

  img.img-fluid.add_custom_css_bred_crum {
    height: 58px !important;
  }

  .top-left.add_custom_css_bred_crum_top {
    margin-top: -56px !important;
  }

  .StickyContactButton_stickyButton__gLEeM {
    top: 49% !important;
    /* right: 11px !important; */
  }

  .retail-section-title2 {
    padding-bottom: 18px !important;
  }

  .top-left {
    margin-top: -36px;
    margin-left: 25px;
    padding: 7px 0px;
  }
  .image-banner-mobile{
    /* margin: 0px !important; */
    padding-top: 0px !important;
    color: white;
    
  }
  /* .home-banner-backgound-image-set-swiper {
    text-align: center !important;
    height: 500px;
  } */
  .home-banner-backgound-image-set-swiper {
    position: relative;
    z-index: 1;
    color: white;
    text-align: center !important;
    padding: 20px;
    /* height: 500px !important; */
  }
  /* .home-banner-img {
    width: 100%;
    height: 100%;
  } */
  /* .home-banner-img {
    width: 322px;
    height: 253px;
  } */
  .home_banner_div{
    height:100%;
    padding:0 5%
  }
  .home_banner_div_img{
    width:100%;
  }
}

@media only screen and (min-width: 800px) and (max-width: 991px) {
  .overview-img1 .image img {
    max-width: 307px !important;
  }

  .retail-section-title2.text-center h2 {
    font-size: 23px !important;
  }

  .r-title.pro-sol {
    font-size: 20px !important;
    display: table;
    text-align: center !important;
    margin-left: 88px !important;
  }

  .top-left {
    margin-left: 43px;
  }

  .retail-heading {
    width: 79% !important;
  }

  /* .breadcrum1 {
    margin-top: -8px !important;
  } */

  .faq-section
    .faq-wrap
    .faq-accordion
    .accordion-item
    .accordion-header
    .accordion-button {
    font-size: 16px !important;
  }

  .faq-section .faq-wrap .faq-accordion .accordion-item .accordion-body p {
    font-size: 16px;
    line-height: 30px;
    color: #4c4d56;
    margin-left: 53px;
  }
}
.pressrelease_p_class {
  font-style: italic;
  font-size: 18px !important;
  font-weight: 600 !important;
}

.ol_press_release_blog {
  list-style: auto;
  font-size: 14px;
  padding: 5px 20px;
  line-height: 31px;
}
.pressrelease_add_ul_tag_class {
  list-style: disc;
  font-size: 14px;
  padding: 10px 25px;
}
.fw-bold-500 {
  font-weight: 500 !important;
}
.ul_blog_rodigo_class {
  list-style: disc;
  padding: 2px;
  margin-left: 29px;
}
.fw-bold-800 {
  font-weight: 700 !important;
  font-size: 1rem !important;
}
.font-weight-100 {
  font-weight: 100 !important;
}
.ol_style_new {
  list-style: auto;
}
.ul_style_new {
  list-style: disc;
}
.li_add_class {
  padding: 3px;
  margin-left: 38px;
}
.fw-bold_1 {
  font-weight: bolder !important;
}
.remove_cursor_color:hover {
  color: #4c4d56;
  cursor: text;
}
.li_add_class_bold_text {
  padding: 3px;
  margin-left: 38px;
  font-weight: bolder;
}
.book_meet_style {
  color: #00aeef;
  font-weight: bold;
}
.underline_class_you_tube {
  color: #0d6efd;
  text-decoration: underline;
}
.ul_style_new_margin {
  list-style-type: disc;
  margin-left: 5%;
}
.display_class_inline {
  display: inline-flex;
}
.block_quta_style {
  max-width: 890px;
  margin: 0 auto;
}
.text-image-larger{
  font-size: larger !important;
}
.upComing-HomeClass > .swiper-pagination {
  display: contents !important;
}
.single-max-width {
  max-width: 1250px;
}
.home-banner-backgound-image-set {
  /* background-image: url("/assets/images/homebanner/master-bg.webp");
  background-size: cover;
  background-attachment: fixed; */
  position: relative; 
  width: 100%;
  overflow: hidden;
}

.home-banner-backgound-image-set-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}
.home-banner-backgound-image-set-swiper {
  position: relative;
  z-index: 1;
  color: white;
  /* text-align: center; */
  padding: 20px;
  height: 600px;
}
.infinity-image-cLass {
  width: 71% !important;
  padding: 14px;
}
.infinity-image-cLass-1 {
  width: 80% !important;
  padding: 14px;
}

.gallery_card:hover {
  transform: translate3d(0px, -8px, 0px);
}
.common-product-solution-bg {
  background: linear-gradient(
    90deg,
    rgba(238, 233, 233, 1) 0%,
    rgba(255, 255, 255, 1) 30%,
    rgba(238, 233, 233, 1) 100%
  );
}
.scenario-list {
  list-style-type: disc;
  list-style: initial;
  text-align: justify;
  font-size: 16px;
  padding: 0px 27px;
}

/*--------------------Banner css start -------------------------*/
.tech-hero-section-6 .hero-images.sk1 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.tech-hero-section-6 .hero-images.sk2 {
  padding-top: 40px;
  padding-bottom: 40px;
}

.tech-hero-section-6 .hero-content1 {
  max-width: 620px;
  padding: 70px 0;
}

.tech-hero-section-6 .hero-content2 {
  max-width: 620px;
  padding: 60px 0;
  margin-top: -130px;
}

.tech-hero-section-6 .hero-content1 .title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  color: #fff;
}

.tech-hero-section-6 .hero-content2 .title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 400;
  color: #fff;
}

.tech-hero-section-6 .hero-content {
  margin-top: -70px;
}

.tech-hero-section-6 .hero-content1 {
  margin-top: -110px;
}

@media only screen and (min-width: 1372px) and (max-width: 1380px) {

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 30px !important;
    height: 30px !important;
  }

  :root {
    --swiper-navigation-size: 30px;
  }
}

@media only screen and (min-width: 1361px) and (max-width: 1370px) {
  .tech-hero-section-6 .hero-content1 {
    max-width: 620px;
    padding: 70px 0;
    margin-top: -10px;
    margin-left: 35px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 30px !important;
    height: 30px !important;
  }

  :root {
    --swiper-navigation-size: 30px;
  }
}

@media only screen and (min-width: 1340px) and (max-width: 1360px) {

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 30px !important;
    height: 30px !important;
  }

  :root {
    --swiper-navigation-size: 30px;
  }

  .tech-hero-section-6 .hero-content2 {
    max-width: 620px;
    padding: 60px 0;
    margin-top: -100px;
    margin-left: 63px;
  }

  .tech-hero-section-6 .hero-content1 {
    max-width: 620px;
    padding: 70px 0;
    margin-top: -30px;
    margin-left: 100px;
  }

  .hero-images.sk1 .images img {
    margin-top: 0px;
  }
}

@media only screen and (min-width: 1645px) and (max-width: 1690px) {
  .tech-hero-section-6 .hero-content {
    margin-top: -75px;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: -40px;
  }
}

@media only screen and (min-width: 1599px) and (max-width: 1640px) {
  .tech-hero-section-6 .hero-content {
    margin-top: -75px;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: -40px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1290px) {
  .tech-hero-section-6 .hero-content .title {
    margin-left: 60px !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 30px !important;
    height: 30px !important;
  }

  :root {
    --swiper-navigation-size: 30px;
  }

  .tech-hero-section-6 .hero-content1 .title {
    margin-left: 30px !important;
  }

  h3.sub-title {
    margin-left: 30px !important;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1160px) {
  .tech-hero-section-6 .hero-content1 {
    /* margin-top: -30px; */
    margin-top: -55px;
    margin-left: 105px;
    font-size: 20px !important;
  }

  .tech-hero-section-6 .hero-content2 {
    max-width: 620px;
    padding: 60px 0;
    /* margin-top: 72px; */
    margin-top: -80px;
    margin-left: 173px;
  }

  .tech-hero-section-6 .hero-content {
    /* margin-top: -66px; */
    margin-top: -70px;
    margin-left: 44px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    width: 30px !important;
    height: 30px !important;
  }

  :root {
    --swiper-navigation-size: 30px;
  }
}

@media only screen and (min-width: 481px) and (max-width: 490px) {
  .hero-content2 {
    text-align: center !important;
  }

  .media-center-tab {
    width: 47vw !important;
  }

  .content.sports {
    padding: 24px !important;
  }

  .content.slots {
    padding: 24px !important;
  }

  .content.instant {
    padding: 24px !important;
  }

  .content.bingo {
    padding: 24px !important;
  }

  .content.lotto {
    padding: 24px !important;
  }

  .head-title.text-white {
    font-size: 18px !important;
  }

  .py-5 {
    padding-bottom: 0rem !important;
  }

  .full-width {
    margin-top: 28px !important;
  }

  .text-white.text-center.py-5.fw-bold.display-5.title {
    font-size: 24px !important;
  }

  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    margin-left: 20px;
    gap: 6px;
  }

  .StickyContactButton_popupHeader__I8hvt {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .StickyContactButton_popup__MpOZV {
    right: 11% !important;
    width: 324px !important;
    border-radius: 30px 30px 17px 17px !important;
    top: 109px !important;
    height: 479px !important;
  }

  .hero-content h2 {
    text-align: center !important;
  }

  .hero-content1 {
    text-align: center !important;
  }

  .hero-content1 h2 {
    text-align: center !important;
  }

  .tech-hero-section-6 .hero-content {
    padding-top: 0px;
    padding-left: 59px;
    margin-top: -67px;
  }

  .tech-hero-section-6 .hero-content2 {
    max-width: 620px;
    padding: 60px 0;
    /* margin-top: -110px; */
    text-align: center;
    margin-left: 36px;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: -134px;
    text-align: center;
    margin-left: 26px;
  }

  .tech-hero-section-6 .hero-content1 .title {
    font-size: 27px;
    line-height: 34px;
  }

  .swiper.swiper-initialized.swiper-horizontal.home-banner-top-swiper.swiper-backface-hidden
    .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 24px !important;
    height: 24px !important;
  }

  :root {
    --swiper-navigation-size: 24px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 480px) {
  .hero-content2 {
    text-align: center !important;
  }

  .media-center-tab {
    width: 47vw !important;
  }

  .content.slots {
    padding: 24px !important;
  }

  .content.instant {
    padding: 24px !important;
  }

  .content.bingo {
    padding: 24px !important;
  }

  .content.lotto {
    padding: 24px !important;
  }

  .content.sports {
    padding: 24px !important;
  }

  .head-title.text-white {
    font-size: 18px !important;
  }

  .py-5 {
    padding-bottom: 0rem !important;
  }

  .text-white.text-center.py-5.fw-bold.display-5.title {
    font-size: 24px !important;
  }

  .full-width {
    margin-top: 28px !important;
  }

  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    margin-left: 20px;
    gap: 6px;
  }

  .StickyContactButton_popupHeader__I8hvt {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .StickyContactButton_popup__MpOZV {
    right: 11% !important;
    width: 324px !important;
    border-radius: 30px 30px 17px 17px !important;
    top: 109px !important;
    height: 479px !important;
  }

  .downloadpopup_popup__In2QG {
    top: 99px !important;
    height: 506px !important;
  }

  .hero-content h2 {
    text-align: center !important;
  }

  .hero-content1 {
    text-align: center !important;
  }

  .hero-content1 h2 {
    text-align: center !important;
  }

  .tech-hero-section-6 .hero-content {
    padding-top: 0px;
    padding-left: 59px;
    margin-top: -67px;
  }

  .tech-hero-section-6 .hero-content2 {
    max-width: 620px;
    padding: 60px 0;
    /* margin-top: -110px; */
    text-align: center;
    margin-left: 36px;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: -134px;
    text-align: center;
    margin-left: 26px;
  }

  .tech-hero-section-6 .hero-content1 .title {
    font-size: 27px;
    line-height: 34px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 24px !important;
    height: 24px !important;
  }

  :root {
    --swiper-navigation-size: 24px;
  }
}

@media only screen and (min-width: 422px) and (max-width: 435px) {
  .hero-content2 {
    text-align: center !important;
  }

  .media-center-tab {
    width: 47vw !important;
  }

  .content.slots {
    padding: 24px !important;
  }

  .content.instant {
    padding: 24px !important;
  }

  .content.bingo {
    padding: 24px !important;
  }

  .content.lotto {
    padding: 24px !important;
  }

  .content.sports {
    padding: 24px !important;
  }

  .head-title.text-white {
    font-size: 18px !important;
  }

  .py-5 {
    padding-bottom: 0rem !important;
  }

  .text-white.text-center.py-5.fw-bold.display-5.title {
    font-size: 24px !important;
  }

  .full-width {
    margin-top: 28px !important;
  }

  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    margin-left: 20px;
    gap: 6px;
  }

  .StickyContactButton_popupHeader__I8hvt {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .StickyContactButton_popup__MpOZV {
    right: 11% !important;
    width: 324px !important;
    border-radius: 30px 30px 17px 17px !important;
    top: 109px !important;
    height: 479px !important;
  }

  .downloadpopup_popup__In2QG {
    top: 99px !important;
    height: 506px !important;
  }

  .hero-content h2 {
    text-align: center !important;
  }

  .hero-content1 {
    text-align: center !important;
  }

  .hero-content1 h2 {
    text-align: center !important;
  }

  .hero-content {
    margin-top: -128px !important;
    text-align: center !important;
    margin-left: 39px !important;
  }

  .tech-hero-section-6 .hero-content1 {
    margin-top: -115px;
    text-align: center;
  }

  .tech-hero-section-6 .hero-content2 {
    /* margin-top: -112px; */
    text-align: center;
    margin-left: 22px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 24px !important;
    height: 24px !important;
  }

  :root {
    --swiper-navigation-size: 24px;
  }
}

@media only screen and (min-width: 414px) and (max-width: 420px) {
  .hero-content2 {
    text-align: center !important;
  }

  .content.bingo {
    padding: 24px !important;
  }

  .media-center-tab {
    width: 47vw !important;
  }

  .content.slots {
    padding: 24px !important;
  }

  .content.instant {
    padding: 24px !important;
  }

  .content.lotto {
    padding: 24px !important;
  }

  .content.sports {
    padding: 24px !important;
  }

  .head-title.text-white {
    font-size: 18px !important;
  }

  .py-5 {
    padding-bottom: 0rem !important;
  }

  .text-white.text-center.py-5.fw-bold.display-5.title {
    font-size: 24px !important;
  }

  .full-width {
    margin-top: 28px !important;
  }

  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    margin-left: 20px;
    gap: 6px;
  }

  .StickyContactButton_popupHeader__I8hvt {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .StickyContactButton_popup__MpOZV {
    right: 11% !important;
    width: 324px !important;
    border-radius: 30px 30px 17px 17px !important;
    top: 109px !important;
    height: 479px !important;
  }

  .section-title2 p {
    text-align: center !important;
  }

  .service-section-2
    .service-wrap-2
    .service-content-wrap-2
    .service-item
    .service-content {
    padding: 8px !important;
  }

  .iso-9001 {
    margin-left: 14px !important;
  }

  .downloadpopup_popup__In2QG {
    top: 99px !important;
    height: 506px !important;
  }

  .hero-content h2 {
    text-align: center !important;
  }

  .hero-content1 {
    text-align: center !important;
  }

  .hero-content1 h2 {
    text-align: center !important;
  }

  /* .tech-hero-section-6 .hero-content {
    padding-top: 136px !important;
    margin-top: -229px !important;
    text-align: center !important;
    margin-left: 29px;
  } */
  .tech-hero-section-6 .hero-content {
    padding-top: 136px !important;
    margin-top: -187px !important;
    text-align: center !important;
    margin-left: 68px;
  }

  .hero-content b {
    font-size: 36px !important;
  }

  .tech-hero-section-6 .hero-content2 {
    max-width: 620px;
    padding: 60px 0;
    margin-top: -130px;
    text-align: center;
  }

  .tech-hero-section-6 .hero-content2 {
    margin-left: 69px !important;
  }

  .hero-images.sk .images img {
    margin-left: 15px;
  }

  .tech-hero-section-6 .hero-content1 {
    max-width: 620px;
    padding: 70px 0;
    margin-top: -100px;
    text-align: center;
    margin-left: 41px !important;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 24px !important;
    height: 24px !important;
  }

  :root {
    --swiper-navigation-size: 24px;
  }
}

@media only screen and (min-width: 408px) and (max-width: 413px) {
  .hero-content2 {
    text-align: center !important;
  }

  .content.instant {
    padding: 24px !important;
  }

  .content.bingo {
    padding: 24px !important;
  }

  .content.lotto {
    padding: 24px !important;
  }

  .content.slots {
    padding: 24px !important;
  }

  .content.sports {
    padding: 24px !important;
  }

  .head-title.text-white {
    font-size: 18px !important;
  }

  .py-5 {
    padding-bottom: 0rem !important;
  }

  .text-white.text-center.py-5.fw-bold.display-5.title {
    font-size: 24px !important;
  }

  .full-width {
    margin-top: 28px !important;
  }

  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    margin-left: 20px;
    gap: 6px;
  }

  .StickyContactButton_popupHeader__I8hvt {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .StickyContactButton_popup__MpOZV {
    right: 11% !important;
    width: 324px !important;
    border-radius: 30px 30px 17px 17px !important;
    top: 109px !important;
    height: 479px !important;
  }

  .downloadpopup_popup__In2QG {
    top: 99px !important;
    height: 506px !important;
  }

  .hero-content h2 {
    text-align: center !important;
  }

  .hero-content1 {
    text-align: center !important;
  }

  .hero-content1 h2 {
    text-align: center !important;
  }

  .tech-hero-section-6 .hero-content {
    padding-top: 90px !important;
    margin-top: -103px !important;
    text-align: center !important;
    margin-left: 46px;
  }

  .tech-hero-section-6 .hero-content1 {
    max-width: 620px;
    padding: 70px 0;
    margin-top: -90px !important;
    text-align: center;
    margin-left: 25px;
  }

  .tech-hero-section-6 .hero-content2 {
    max-width: 620px;
    padding: 60px 0;
    margin-top: -112px !important;
    text-align: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 24px !important;
    height: 24px !important;
  }

  .media-center-tab {
    width: 47vw !important;
  }

  :root {
    --swiper-navigation-size: 24px;
  }
}

@media only screen and (min-width: 399px) and (max-width: 408px) {
  .hero-content2 {
    text-align: center !important;
  }

  .content.instant {
    padding: 24px !important;
  }

  .media-center-tab {
    width: 47vw !important;
  }

  .content.bingo {
    padding: 24px !important;
  }

  .content.slots {
    padding: 24px !important;
  }

  .content.lotto {
    padding: 24px !important;
  }

  .content.sports {
    padding: 24px !important;
  }

  .head-title.text-white {
    font-size: 18px !important;
  }

  .py-5 {
    padding-bottom: 0rem !important;
  }

  .text-white.text-center.py-5.fw-bold.display-5.title {
    font-size: 24px !important;
  }

  .full-width {
    margin-top: 28px !important;
  }

  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    margin-left: 20px;
    gap: 6px;
  }

  .StickyContactButton_popupHeader__I8hvt {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .StickyContactButton_popup__MpOZV {
    right: 11% !important;
    width: 324px !important;
    border-radius: 30px 30px 17px 17px !important;
    top: 109px !important;
    height: 479px !important;
  }

  .downloadpopup_popup__In2QG {
    top: 99px !important;
    height: 506px !important;
  }

  .hero-content h2 {
    text-align: center !important;
  }

  .hero-content1 {
    text-align: center !important;
  }

  .hero-content1 h2 {
    text-align: center !important;
  }

  .tech-hero-section-6 .hero-content {
    padding-top: 0px !important;
    padding-left: 2px !important;
    margin-top: -90px !important;
    text-align: center;
    margin-left: 11px;
  }

  .hero-images.sk .images img {
    margin-left: 5px;
  }

  .tech-hero-section-6 .hero-content1 {
    max-width: 620px;
    padding: 70px 0;
    margin-top: -137px;
    text-align: center;
  }

  .tech-hero-section-6 .hero-content1 .title {
    font-size: 25px;
    line-height: 30px;
  }

  .tech-hero-section-6 .hero-content2 .title {
    font-size: 26px;
    line-height: 32px;
  }

  .tech-hero-section-6 .hero-content2 {
    max-width: 620px;
    padding: 60px 0;
    text-align: center;
    margin-left: 30px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 24px !important;
    height: 24px !important;
  }

  :root {
    --swiper-navigation-size: 24px;
  }
}

@media only screen and (min-width: 370px) and (max-width: 395px) {
  .hero-content2 {
    text-align: center !important;
  }

  .media-center-tab {
    width: 47vw !important;
  }

  .content.instant {
    padding: 24px !important;
  }

  .content.bingo {
    padding: 24px !important;
  }

  .content.slots {
    padding: 24px !important;
  }

  .content.lotto {
    padding: 24px !important;
  }

  .content.sports {
    padding: 24px !important;
  }

  .head-title.text-white {
    font-size: 18px !important;
  }

  .py-5 {
    padding-bottom: 0rem !important;
  }

  .text-white.text-center.py-5.fw-bold.display-5.title {
    font-size: 24px !important;
  }

  .full-width {
    margin-top: 28px !important;
  }

  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    margin-left: 20px;
    gap: 6px;
  }

  .StickyContactButton_popupHeader__I8hvt {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .StickyContactButton_popup__MpOZV {
    right: 11% !important;
    width: 324px !important;
    border-radius: 30px 30px 17px 17px !important;
    top: 109px !important;
    height: 479px !important;
  }

  .downloadpopup_popup__In2QG {
    top: 99px !important;
    height: 506px !important;
  }

  .hero-content h2 {
    text-align: center !important;
    margin-left: 20px !important;
  }

  .hero-content1 {
    text-align: center !important;
  }

  .hero-content1 h2 {
    text-align: center !important;
    margin-left: 20px !important;
  }

  .tech-hero-section-6 .hero-content {
    padding-top: 0px !important;
    padding-left: 2px !important;
    margin-top: -50px !important;
    text-align: center;
    margin-left: 11px;
  }

  .hero-images.sk .images img {
    margin-left: 5px;
  }

  .tech-hero-section-6 .hero-content1 {
    max-width: 620px;
    padding: 70px 0;
    margin-top: -90px !important;
    text-align: center;
  }

  .tech-hero-section-6 .hero-content1 .title {
    font-size: 25px;
    line-height: 30px;
  }

  .tech-hero-section-6 .hero-content2 .title {
    font-size: 26px;
    line-height: 32px;
  }

  .tech-hero-section-6 .hero-content2 {
    max-width: 620px;
    padding: 60px 0;
    margin-top: -90px !important;
    text-align: center;
    margin-left: 23px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 24px !important;
    height: 24px !important;
  }

  :root {
    --swiper-navigation-size: 24px;
  }
}

@media only screen and (min-width: 330px) and (max-width: 360px) {
  .hero-content2 {
    text-align: center !important;
  }

  .media-center-tab {
    width: 47vw !important;
  }

  .content.lotto {
    padding: 24px !important;
  }

  .content.instant {
    padding: 24px !important;
  }

  .content.slots {
    padding: 24px !important;
  }

  .content.bingo {
    padding: 24px !important;
  }

  .content.sports {
    padding: 24px !important;
  }

  .head-title.text-white {
    font-size: 18px !important;
  }

  .py-5 {
    padding-bottom: 0rem !important;
  }

  .text-white.text-center.py-5.fw-bold.display-5.title {
    font-size: 24px !important;
  }

  .full-width {
    margin-top: 28px !important;
  }

  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    margin-left: 20px;
    gap: 6px;
  }

  .StickyContactButton_popupHeader__I8hvt {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .StickyContactButton_popup__MpOZV {
    right: 5% !important;
    width: 324px !important;
    border-radius: 30px 30px 17px 17px !important;
    top: 109px !important;
    height: 479px !important;
  }

  .downloadpopup_popup__In2QG {
    top: 99px !important;
    height: 506px !important;
    width: 321px !important;
  }

  :root {
    --swiper-navigation-size: 24px;
  }

  .hero-content h2 {
    text-align: center !important;
  }

  .hero-content1 {
    text-align: center !important;
  }

  .hero-content1 h2 {
    text-align: center !important;
  }

  .tech-hero-section-6 .hero-content {
    padding-top: 0px;
    padding-left: 0px;
    margin-top: -58px;
    text-align: center !important;
    margin-left: 20px;
  }

  .tech-hero-section-6 .hero-content1 {
    max-width: 620px;
    padding: 70px 0;
    margin-top: -150px;
    text-align: center;
  }

  .tech-hero-section-6 .hero-content2 {
    padding: 60px 0;
    /* margin-top: -130px; */
    text-align: center;
  }

  .tech-hero-section-6 .hero-content1 .title {
    font-size: 28px;
    line-height: 36px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 24px !important;
    height: 24px !important;
  }
}

@media only screen and (min-width: 310px) and (max-width: 328px) {
  .hero-content2 {
    text-align: center !important;
  }

  .media-center-tab {
    width: 47vw !important;
  }

  .content.lotto {
    padding: 24px !important;
  }

  .content.slots {
    padding: 24px !important;
  }

  .content.instant {
    padding: 24px !important;
  }

  .content.bingo {
    padding: 24px !important;
  }

  .content.sports {
    padding: 24px !important;
  }

  .head-title.text-white {
    font-size: 18px !important;
  }

  .text-white.text-center.py-5.fw-bold.display-5.title {
    font-size: 24px !important;
  }

  .py-5 {
    padding-bottom: 0rem !important;
  }

  .full-width {
    margin-top: 28px !important;
  }

  .header-section-2 .header-wrap .header-meta .header-btn-2 {
    margin-left: 20px;
    gap: 6px;
  }

  .StickyContactButton_popupHeader__I8hvt {
    border-top-left-radius: 30px !important;
    border-top-right-radius: 30px !important;
  }

  .StickyContactButton_popup__MpOZV {
    right: 11% !important;
    width: 324px !important;
    border-radius: 30px 30px 17px 17px !important;
    top: 109px !important;
    height: 479px !important;
  }

  .downloadpopup_popup__In2QG {
    top: 99px !important;
    height: 506px !important;
  }

  :root {
    --swiper-navigation-size: 24px;
  }

  .hero-content h2 {
    text-align: center !important;
  }

  .hero-content1 {
    text-align: center !important;
  }

  .hero-content1 h2 {
    text-align: center !important;
  }

  .tech-hero-section-6 .hero-content {
    padding-top: 0px;
    padding-left: 0px;
    margin-top: -58px;
    text-align: center !important;
    margin-left: 20px;
  }

  .tech-hero-section-6 .hero-content1 {
    max-width: 620px;
    padding: 70px 0;
    margin-top: -150px;
    text-align: center;
  }

  .tech-hero-section-6 .hero-content2 {
    padding: 60px 0;
    /* margin-top: -130px; */
    text-align: center;
  }

  .tech-hero-section-6 .hero-content1 .title {
    font-size: 28px;
    line-height: 36px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    top: var(--swiper-navigation-top-offset, 50%);
    width: 24px !important;
    height: 24px !important;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1480px) {
  p.title-scan-play {
    font-size: 24px !important;
  }
}
.home-banner-text-h3 {
  font-size: 25px;
  color: white !important;
}
.disk_list{
  list-style-type: disc;
  margin-left: 25px;
  line-height: 26px;
}
.circle_list{
 list-style-type: circle;
 margin-left: 25px;
 line-height: 26px;
}
.ol_auto{
  list-style-type: auto;
  margin-left: 25px;
  line-height: 26px;
}

.event-swiper .swiper-button-prev,
.event-swiper .swiper-button-next {
  background-color: #cac6c6db;
  width: 35px;
  height: 62px;
}

