@font-face {
    font-family: "flaticon";
    src: url("../fonts/flaticon.ttf?9da6da3c6ae6f38ca4698081ef934ea0") format("truetype"),
        url("../fonts/flaticon.woff?9da6da3c6ae6f38ca4698081ef934ea0") format("woff"),
        url("../fonts/flaticon.woff2?9da6da3c6ae6f38ca4698081ef934ea0") format("woff2"),
        url("../fonts/flaticon.eot?9da6da3c6ae6f38ca4698081ef934ea0#iefix") format("embedded-opentype"),
        url("../fonts/flaticon.svg?9da6da3c6ae6f38ca4698081ef934ea0#flaticon") format("svg");
}


i[class^="flaticon-"]:before,
i[class*=" flaticon-"]:before {
    font-family: flaticon !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.flaticon-like:before {
    content: "\f100";
}

.flaticon-menu:before {
    content: "\f101";
}

.flaticon-loupe:before {
    content: "\f102";
}

.flaticon-shopping-basket:before {
    content: "\f103";
}

.flaticon-location-pin:before {
    content: "\f104";
}

.flaticon-send:before {
    content: "\f105";
}

.flaticon-right-arrow:before {
    content: "\f106";
}

.flaticon-left-arrow:before {
    content: "\f107";
}

.flaticon-left-arrow-1:before {
    content: "\f108";
}

.flaticon-right-arrow-1:before {
    content: "\f109";
}

.flaticon-youtube:before {
    content: "\f10a";
}

.flaticon-vegetable:before {
    content: "\f10b";
}

.flaticon-water-bottle:before {
    content: "\f10c";
}

.flaticon-healthcare:before {
    content: "\f10d";
}

.flaticon-mortarboard:before {
    content: "\f10e";
}

.flaticon-atoms:before {
    content: "\f10f";
}

.flaticon-maternity:before {
    content: "\f110";
}

.flaticon-plus:before {
    content: "\f111";
}

.flaticon-supermarket-gift:before {
    content: "\f112";
}

.flaticon-gift:before {
    content: "\f113";
}

.flaticon-play:before {
    content: "\f114";
}

.flaticon-right-quote:before {
    content: "\f115";
}

.flaticon-user:before {
    content: "\f116";
}

.flaticon-user-1:before {
    content: "\f117";
}

.flaticon-set-square:before {
    content: "\f118";
}

.flaticon-bell:before {
    content: "\f119";
}

.flaticon-book:before {
    content: "\f11a";
}

.flaticon-dollar-currency-symbol:before {
    content: "\f11b";
}

.flaticon-right-arrow-2:before {
    content: "\f11c";
}

.flaticon-wall-clock:before {
    content: "\f11d";
}

.flaticon-folder:before {
    content: "\f11e";
}

.flaticon-email:before {
    content: "\f11f";
}

.flaticon-startup:before {
    content: "\f120";
}

.flaticon-diamond:before {
    content: "\f121";
}

.flaticon-gift-1:before {
    content: "\f122";
}

.flaticon-call:before {
    content: "\f123";
}

.flaticon-shopping-bag:before {
    content: "\f124";
}

.flaticon-idea:before {
    content: "\f125";
}

.flaticon-idea-1:before {
    content: "\f126";
}

.flaticon-target:before {
    content: "\f127";
}

.flaticon-bar-chart:before {
    content: "\f128";
}

.flaticon-fitness:before {
    content: "\f129";
}

.flaticon-healthy-food:before {
    content: "\f12a";
}

.flaticon-dumbbell:before {
    content: "\f12b";
}

.flaticon-play-button:before {
    content: "\f12c";
}

.flaticon-plus-1:before {
    content: "\f12d";
}

.flaticon-add:before {
    content: "\f12e";
}

.flaticon-left-quote:before {
    content: "\f12f";
}

.flaticon-right-quote-1:before {
    content: "\f130";
}

.flaticon-checked:before {
    content: "\f131";
}

.flaticon-check:before {
    content: "\f132";
}

.flaticon-tick:before {
    content: "\f133";
}

.flaticon-like-1:before {
    content: "\f134";
}

.flaticon-like-2:before {
    content: "\f135";
}

.flaticon-heart:before {
    content: "\f136";
}

.flaticon-quote-left:before {
    content: "\f137";
}

.flaticon-link:before {
    content: "\f138";
}

.flaticon-worldwide:before {
    content: "\f139";
}

.flaticon-edit:before {
    content: "\f13a";
}

.flaticon-star:before {
    content: "\f13b";
}

.flaticon-star-1:before {
    content: "\f13c";
}

.flaticon-copy:before {
    content: "\f13d";
}

.flaticon-book-1:before {
    content: "\f13e";
}

.flaticon-settings:before {
    content: "\f13f";
}

.flaticon-calendar:before {
    content: "\f140";
}

.flaticon-price-tag:before {
    content: "\f141";
}

.flaticon-edit-file:before {
    content: "\f142";
}

.flaticon-bell-1:before {
    content: "\f143";
}

.flaticon-padlock:before {
    content: "\f144";
}

.flaticon-house:before {
    content: "\f145";
}

.flaticon-chat:before {
    content: "\f146";
}

.flaticon-attachment:before {
    content: "\f147";
}

.flaticon-upload:before {
    content: "\f148";
}

.flaticon-file:before {
    content: "\f149";
}

.flaticon-shield:before {
    content: "\f14a";
}

.flaticon-share:before {
    content: "\f14b";
}

.flaticon-black-circle:before {
    content: "\f14c";
}

.flaticon-close:before {
    content: "\f14d";
}

.flaticon-close-1:before {
    content: "\f14e";
}

.flaticon-credit-card:before {
    content: "\f14f";
}

.flaticon-phone-call:before {
    content: "\f150";
}

.flaticon-pin:before {
    content: "\f151";
}

.flaticon-shopping-cart:before {
    content: "\f152";
}

.flaticon-shopping-cart-1:before {
    content: "\f153";
}

.flaticon-right-arrow-3:before {
    content: "\f154";
}

.flaticon-left-arrow-2:before {
    content: "\f155";
}

.flaticon-user-2:before {
    content: "\f156";
}

.flaticon-avatar:before {
    content: "\f157";
}

.flaticon-straight-quotes:before {
    content: "\f158";
}

.flaticon-heart-1:before {
    content: "\f159";
}

.flaticon-settings-1:before {
    content: "\f15a";
}

.flaticon-profits:before {
    content: "\f15b";
}

.flaticon-graph:before {
    content: "\f15c";
}

.flaticon-graph-1:before {
    content: "\f15d";
}

.flaticon-graph-2:before {
    content: "\f15e";
}

.flaticon-graphics-scale:before {
    content: "\f15f";
}

.flaticon-bar-chart-1:before {
    content: "\f160";
}

.flaticon-update:before {
    content: "\f161";
}

.flaticon-unlock:before {
    content: "\f162";
}

.flaticon-padlock-1:before {
    content: "\f163";
}

.flaticon-question:before {
    content: "\f164";
}

.flaticon-help:before {
    content: "\f165";
}

.flaticon-task:before {
    content: "\f166";
}

.flaticon-copy-file:before {
    content: "\f167";
}

.flaticon-google-docs:before {
    content: "\f168";
}

.flaticon-english-language:before {
    content: "\f169";
}

.flaticon-translate:before {
    content: "\f16a";
}

.flaticon-upload-1:before {
    content: "\f16b";
}

.flaticon-download:before {
    content: "\f16c";
}

.flaticon-back:before {
    content: "\f16d";
}

.flaticon-next:before {
    content: "\f16e";
}

.flaticon-delete:before {
    content: "\f16f";
}

.flaticon-horizontal-line-remove-button:before {
    content: "\f170";
}

.flaticon-online-learning:before {
    content: "\f171";
}