@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(100%, 100%);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translate(0, 0);
  }

  to {
    opacity: 0;
    transform: translate(100%, 100%);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.stickyButton {
  position: fixed;
  top: 62%;
  right: 0;
  transform: translateY(-50%);
  z-index: 1055;
  background: #00aeef;
  /* background: linear-gradient(45deg, #F17C58, #E94584, #24AADB, #27DBB1, #FFDC18, #FF3706); */
  animation: gradient 5s cubic-bezier(0.46, 0.03, 0.52, 0.96) infinite;
  -webkit-animation: gradient 3s ease infinite;
  height: 20vh;
  width: 30px;
  color: white;
  text-align: center;
  line-height: 20vh;
  padding: 0.3rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 10px 0 0 10px;
  writing-mode: vertical-rl;
}

.stickyButton label {
  display: block;
  transform: rotate(180deg);
}

.stickyButton:hover {
  opacity: 0.8;
}

.popup {
  position: fixed;
  bottom: 5px;
  right: 5%;
  width: 300px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  animation: slideIn 0.4s ease forwards;
}

.popup.close {
  animation: slideOut 0.4s ease forwards;
}

.popupHeader {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00aeef;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  position: relative;
}

.popupHeader h5 {
  flex: 1;
  text-align: center;
  margin: 0;
  color: white;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}

.popupBody {
  padding: 20px;
}

.inputField,
.textareaField {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.textareaField {
  resize: none;
  height: 80px;
}

.submitButton {
  width: 100%;
  background-color: #00aeef;
  color: white;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.submitButton:hover {
  background-color: #00afefe3;
}
